<p-table
  #dt
  paginatorDropdownAppendTo="body"
  [value]="patients"
  dataKey="id"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [loading]="loading"
  [paginator]="true"
  paginatorDropdownAppendTo="body"
  responsiveLayout="scroll"
  breakpoint="100px"
  styleClass="p-datatable-striped no-override"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [filterDelay]="0">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="displayName">
        <div class="flex justify-content-between align-items-center">
          Name
          <p-sortIcon field="displayName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="MedicalRecordNumber">
        <div class="flex justify-content-between align-items-center">
          MRN
          <p-sortIcon field="MedicalRecordNumber"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="WeekDays">
        <div class="flex justify-content-between align-items-center">
          Days
          <p-sortIcon field="WeekDays"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="DurationInMinutes">
        <div class="flex justify-content-between align-items-center">
          Duration
          <p-sortIcon field="DurationInMinutes"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="StartTime">
        <div class="flex justify-content-between align-items-center">
          Ontime
          <p-sortIcon field="StartTime"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="providerName">
        <div class="flex justify-content-between align-items-center">
          Physician
          <p-sortIcon field="providerName"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="transportation.nvarTranspo_Name">
        <div class="flex justify-content-between align-items-center">
          Transportation
          <p-sortIcon field="transportation.nvarTranspo_Name"></p-sortIcon>
        </div>
      </th>
      <th *ngIf="canWrite">
        <p-button (onClick)="downloadCsv()">
          <i class="fa-solid fa-file-csv mr-2"></i>
          Export CSV
        </p-button>
      </th>
    </tr>
    <tr>
      <th>
        <p-columnFilter
          matchMode="contains"
          type="text"
          [showMenu]="false"
          [showClearButton]="false"
          field="nameFilter"
          class="ml-auto"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          matchMode="contains"
          type="text"
          [showMenu]="false"
          [showClearButton]="false"
          field="MedicalRecordNumber"
          class="ml-auto"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          matchMode="contains"
          type="text"
          [showMenu]="false"
          [showClearButton]="false"
          field="WeekDays"
          class="ml-auto"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          matchMode="contains"
          type="text"
          [showMenu]="false"
          [showClearButton]="false"
          field="durationInHours"
          class="ml-auto"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          matchMode="contains"
          type="text"
          [showMenu]="false"
          [showClearButton]="false"
          field="StartTime"
          class="ml-auto"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          matchMode="contains"
          type="text"
          [showMenu]="false"
          [showClearButton]="false"
          field="providerNameFilter"
          class="ml-auto"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          matchMode="contains"
          type="text"
          [showMenu]="false"
          [showClearButton]="false"
          field="transportation.nvarTranspo_Name"
          class="ml-auto">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown
              [ngModel]="value"
              [options]="transportationOptions"
              (onChange)="filter($event.value)"
              placeholder="Any"
              appendTo="body"
              [showClear]="true">
              <ng-template let-option pTemplate="item">
                <span [class]="'customer-badge status-' + option.value">{{ option.label }}</span>
              </ng-template>
            </p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="canWrite">
        <div class="flex justify-content-between align-items-center">Actions</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-patient>
    <tr class="p-selectable-row">
      <td [ngClass]="['patient-name', patient.HepBStatus]">{{ patient.displayName }}</td>
      <td>{{ patient.MedicalRecordNumber }}</td>
      <td>{{ patient.WeekDays }}</td>
      <td>{{ patient.durationInHours }}</td>
      <td>{{ patient.StartTime }}</td>
      <td>
        {{ patient.providerName }}
      </td>
      <td>{{ patient?.transportation?.nvarTranspo_Name }}</td>
      <td *ngIf="canWrite" class="no-wrap">
        <button
          pButton
          pRipple
          type="button"
          (click)="editPatient(patient)"
          icon="pi pi-user-edit"
          class="action-button mr-2"></button>
        <button
          pButton
          pRipple
          type="button"
          (click)="deletePatient($event, patient)"
          icon="pi pi-times"
          class="action-button p-button-danger"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No patients found.</td>
    </tr>
  </ng-template>
</p-table>

<!-- the popup where it will show otherwise it will appear in a modal -->
<p-confirmPopup></p-confirmPopup>
